import React from "react";

export const Features = () => {
    return (
    <>
    <h1>Coming Soon</h1>
    <div className="text">Further Prediction Horizon</div>
    <div className="text">Team Planner</div>
    <div className="text">Transfer Solver</div>
    </>
    );
};