import React from "react";
import "./pages.css"

export const About = () => {
    return (
        <>
        <h1>How it works</h1>
        <div className="text">
            Carefully trained and backtested on data from the past six Premier League seasons,
            Predict FPL uses a proprietary mix of machine learning and statistical models 
            to forecast players' performance in <a href="https://fantasy.premierleague.com/FPL">Fantasy Premier League</a>.
            The resulting week-by-week point predictions can be used to perfect your FPL team, and plan your transfers.
            </div>
        </>
    );
};