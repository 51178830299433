import "./component.css";
import React from "react"

export const Footer = () => {
    return(
        <div className="main-footer">
            <div className="container">
                {/* <hr /> */}
                <div className="row">
                    <p className="col-sm">
                        &copy;{new Date().getFullYear()} Calvin Walker | calvinswalker@gmail.com
                    </p>
                </div>
            </div>
        </div>
    )
}