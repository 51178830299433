import "./App.css";
import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { Footer } from "./components/footer";
import { NavBar } from "./components/navbar";

import { About, Features, Home } from "./components/pages";


function App() {

  return (
    <div className="page-container">
      <div className="content-wrap">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/features" element={<Features />}/>
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

