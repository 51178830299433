import React from "react";
import "./component.css";

import testData from "../data/predictions.json";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { GlobalFilter } from "./globalfilter"

export function Table() {
    const data = React.useMemo(() => testData, [])
    const columns = React.useMemo(() => [
        {
        Header: "Player",
        accessor: "name",
        },
        {
        Header: "Position",
        accessor: "position",
        },
        {
        Header: "Team",
        accessor: "team",
        },
        {
        Header: "Price",
        accessor: "price",
        },
        {
        Header: "Ownership",
        accessor: "ownership",
        },
        {
        Header: "xPoints GW7",
        accessor: "xPoints",
        },
    ], 
    []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } 
        = useTable({columns, data}, useGlobalFilter, useSortBy);

    return (
        <>
        <div class="search">
            <GlobalFilter 
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
            />
        </div>
            <div className="table-container">
                <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted ? (column.isSortedDesc ? " ▼": " ▲") : "  "}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                            </td>
                        ))}
                        </tr>
                    );
                    })}
                </tbody>
                </table>
            </div>
        </>
    )
}

