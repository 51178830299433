import React from "react";
import "../component.css"

import { Table } from "../table";

export const Home = () => {
    return (
      <div className="content-wrap">
        <Table />
      </div>
    );
};