import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./component.css"

export const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <nav>
        <Link to="/" className="title">
            Predict FPL
        </Link>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <ul className={menuOpen ? "open" : ""}>
            <li>
            <NavLink to="/about">About</NavLink>
            </li>
            <li>
            <NavLink to="/features">Features</NavLink>
            </li>
        </ul>
        </nav>
    );
};

